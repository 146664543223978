import { connect } from 'react-redux';
import Causes from './causes';
import { fetchPhotographs, fetchPhotograph } from '../../actions/work_actions';

const mapStateToProps = (state) => {
  return {
   photographs: state.work.photographs,
   photograph: state.work.photograph
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPhotographs: () => dispatch(fetchPhotographs()),
    fetchPhotograph: (id) => dispatch(fetchPhotograph(id)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Causes);