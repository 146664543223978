import { connect } from 'react-redux';
import Print from './print';
import {fetchPhotograph } from '../../actions/work_actions';

const mapStateToProps = (state) => {
  return {
   photograph: state.work.photograph
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPhotograph: (id) => dispatch(fetchPhotograph(id)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Print);