import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'



export default function Painting(props) {

   const { 
            painting, 
            fetchPainting,
            match } = props

   let id = match.params.id

   const [selectedImage, setSelectedImage] = useState(null)
   const [mainURL, setMainURL] = useState(null)
   const [mainIsProp, setMainIsProp] = useState(false)

   useEffect(() => {
    window.scrollTo(0,0)
  }, [])

   useEffect(() => {
    if (!painting || painting._id !== id)
    fetchPainting(id)

}, [painting])

useEffect(() => {
    if (painting) {
 setSelectedImage(painting.imageURL);
 setMainURL(painting.imageURL)
    }

}, [painting])

function selectImage(image) {
    if (image !== selectedImage && image !== mainURL) {
        setSelectedImage(image)
        setMainIsProp(true)
    } else if (image === mainURL && image !== selectedImage) {
        setSelectedImage(image)
        setMainIsProp(false)

    } else {

    }
}



    return (
      <div className="main-body fade-in">
        <div className="main-page pad-top-50 smaller uppercase black fade-in">
 
        <Link to="/paintings">
        <div className="back-btn">‹ Back to Paintings</div>
        </Link>

        {painting ? 
        <>
        
       
       
            <div className="">
            <div className="half r">
                <img className="painting-image height-max" src={`${selectedImage}`}/>
            </div>
            <div className="half">
                <div className="image-series-box">
                {painting.image1 && painting.image1 !== selectedImage ? <img onClick={() => selectImage(painting.image1)} className="image-series" src={`${painting.image1}`}/> : null}
                {painting.image2 && painting.image2 !== selectedImage ? <img onClick={() => selectImage(painting.image2)} className="image-series" src={`${painting.image2}`}/> : null}
                {painting.image3 && painting.image3 !== selectedImage ? <img onClick={() =>  selectImage(painting.image3)} className="image-series" src={`${painting.image3}`}/> : null}
                {painting.image4 && painting.image4 !== selectedImage ? <img onClick={() =>  selectImage(painting.image4)} className="image-series" src={`${painting.image4}`}/> : null}
                {painting.image5 && painting.image5 !== selectedImage ? <img onClick={() =>  selectImage(painting.image5)} className="image-series" src={`${painting.image5}`}/> : null}
                {painting.image6 && painting.image6 !== selectedImage ? <img onClick={() =>  selectImage(painting.image6)} className="image-series" src={`${painting.image6}`}/> : null}
                {mainIsProp ? <img className="image-series" onClick={() =>  selectImage(mainURL)} src={mainURL}></img> : null}
                </div>
                
                <div className="smaller uppercase">
                    <div>{painting.series ? `From the Series:` + ` ` + `${painting.series}` : null}</div>
                    <div>{painting.title ? `${painting.title}` : null}</div>
                    <div>{painting.size ? `${painting.size}` : null}</div>
                    <div>{painting.price ? `$` + parseInt(`${painting.price}`) : `Inquire For Price`}</div>
                </div>

                <a href={`mailto:contact@lorranlima.com?subject=Hi! I'm interested in this piece ID ${painting._id} &body=Please tell me more about this piece.`}>
                <div className="inquire-btn">
                Inquire About This Piece
                </div>
                </a>
     <br/>
            </div> 
            </div>
        
        </> : null
        }</div>
        
          </div>
        );
    }