import { connect } from 'react-redux';
import CulturePiece from './culturepiece';
import { fetchPiece } from '../../actions/work_actions';

const mapStateToProps = (state) => {
  return {
   photograph: state.work.piece
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPhotograph: (id) => dispatch(fetchPiece(id)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CulturePiece);