import { RECEIVE_PAINTINGS, RECEIVE_PAINTING,
            RECEIVE_PHOTOGRAPHS, RECEIVE_PHOTOGRAPH, RECEIVE_PIECES, RECEIVE_PIECE} from '../actions/work_actions';
  
  const WorkReducer = (state = { paintings: undefined, painting: undefined, photographs: undefined, 
                        photograph: undefined,
                        piece: undefined,
                        pieces: undefined}, action) => {
    Object.freeze(state);
    let newState = Object.assign({}, state);
    switch(action.type) {
      case RECEIVE_PAINTING:
        newState.painting = action.painting.data;
        return newState;
      case RECEIVE_PAINTINGS:
        newState.paintings = action.paintings.data;
        return newState;
      case RECEIVE_PHOTOGRAPH:
            newState.photograph = action.photograph.data;
            return newState;
      case RECEIVE_PHOTOGRAPHS:
            newState.photographs = action.photographs.data;
            return newState;
      case RECEIVE_PIECE:
            newState.piece = action.piece.data;
            return newState;
      case RECEIVE_PIECES:
            newState.pieces = action.pieces.data;
            return newState;
      default:
        return state;
    }
  };
  
  export default WorkReducer;