import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'



export default function Paintings(props) {

   const { fetchPaintings, 
            paintings, 
            painting, 
            fetchPainting } = props

    const [show, setShow] = useState(true)
    const [view, setView] = useState(2)

            useEffect(() => {
                window.scrollTo(0,0)
              }, [])

   useEffect(() => {
       if (!paintings)
    fetchPaintings()

}, [paintings])

useEffect(() => {


}, [])

    return (
      <div className="main-body fade-in">
        <div className="banner fade-in">
        <img className="banner-image kenburns-top" src="https://lorranlima.s3.us-east-2.amazonaws.com/desktoppaintingbanner.jpg"/>
        <img className="banner-image-mobile kenburns-top" src="https://lorranlima.s3.us-east-2.amazonaws.com/mobilepaintingbanner.jpg"/>
        </div>
    {show ? 
        <div className="main-page pad-top-20 smaller  black  top-drop">

        <div className="full-white-back">
        <div className="blend"></div>
        <div className="show-intro uppercase">
                    PAINTINGS
      </div>
      <div className="text-gray">
      All works were created in Lorran's Manhattan-Based Art Studio and all are available for purchase.  Please click on an image for further information.<br/>Contact us if you're interested in a custom piece.
      </div>
      <br/>
      <div className="view">
        View 
        <span onClick={() => setView(1)} className={view === 1 ? "view-spn selected-btn" : "view-spn"}>
          1
        </span>
        <span onClick={() => setView(2)} className={view === 2 ? "view-spn selected-btn" : "view-spn"}>
          2
        </span>
      </div>
      <div className="gray-back">
        {paintings ? 
        <>
        {Object.keys(paintings).map((key) => {
            let series = paintings[key].series
            let title = paintings[key].title
            let size = paintings[key].size
            let price = paintings[key].price
            let sold = paintings[key].sold
            if (!sold) {
            return (
            <Link to={`/paintings/${paintings[key]._id}`}>
                     <div className={view === 1 ? "painting-image-case fade-in" : "painting-image-case-half fade-in"}>
                <div className="painting-image-inside">
                {sold ? <div className="sold">SOLD</div> : null}
                <img className="painting-image" src={`${paintings[key].imageURL}`}/>
                <div className="smaller uppercase">
                    <div className="text-gray">{series ? `From the Series:` + ` ` + `${paintings[key].series}` : null}</div>
                    <div>{title ? `${paintings[key].title}` : null}</div>
                    <div className="text-gray-2">{size ? `${paintings[key].size}` : null}</div>
                    <div className="text-gray">{price ? `$` + parseInt(`${paintings[key].price}`) : `Inquire For Price`}</div>
                </div>
                </div>
            </div> 
            </Link>) 
            }
        })}
        </> : null
        }
        
        {paintings ? 
        <>
        {Object.keys(paintings).sort().reverse().map((key) => {
            let series = paintings[key].series
            let title = paintings[key].title
            let size = paintings[key].size
            let price = paintings[key].price
            let sold = paintings[key].sold
            if (sold) {
            return (
            <Link to={`/paintings/${paintings[key]._id}`}>
                     <div className={view === 1 ? "painting-image-case fade-in" : "painting-image-case-half fade-in"}>
                <div className="painting-image-inside">
                {sold ? <div className="sold">SOLD</div> : null}
                <img className="painting-image" src={`${paintings[key].imageURL}`}/>
                <div className="smaller uppercase">
                    <div className="text-gray">{series ? `From the Series:` + ` ` + `${paintings[key].series}` : null}</div>
                    <div>{title ? `${paintings[key].title}` : null}</div>
                    <div className="text-gray-2">{size ? `${paintings[key].size}` : null}</div>
                    <div className="text-gray">{price ? `$` + parseInt(`${paintings[key].price}`) : `Inquire For Price`}</div>
                </div>
                </div>
            </div> 
            </Link>) 
            }
        })}
        </> : null
        }
        
        
        </div>
        </div>
        
          </div>
          :  <div className="show-full-page">
          <div className="show-intro">
              PAINTINGS
          </div>
          
          
          </div> }
          </div> 
        );
    }