import { connect } from 'react-redux';
import Painting from './painting';
import {fetchPainting } from '../../actions/work_actions';

const mapStateToProps = (state) => {
  return {
   painting: state.work.painting
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPainting: (id) => dispatch(fetchPainting(id)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Painting);