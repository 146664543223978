import axios from 'axios';


export const fetchPainting = (id) => {
    return axios.get(`/api/work/paintings/${id}`)
}

export const fetchPaintings = () => {
    return axios.get(`/api/work/paintings/`)
}

export const fetchPhotograph = (id) => {
    return axios.get(`/api/work/photographs/${id}`)
}

export const fetchPhotographs = () => {
    return axios.get(`/api/work/photographs/`)
}

export const fetchPiece = (id) => {
    return axios.get(`/api/work/culturalpieces/${id}`)
}

export const fetchPieces = () => {
    return axios.get(`/api/work/culturalpieces/`)
}


