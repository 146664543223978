import * as APIUtil from '../util/work_api_util';

export const RECEIVE_PAINTINGS = "RECEIVE_PAINTINGS";
export const RECEIVE_PAINTING = "RECEIVE_PAINTING";
export const RECEIVE_PHOTOGRAPHS = "RECEIVE_PHOTOGRAPHS";
export const RECEIVE_PHOTOGRAPH = "RECEIVE_PHOTOGRAPH";
export const RECEIVE_PIECE = "RECEIVE_PIECE";
export const RECEIVE_PIECES = "RECEIVE_PIECES";


export const receivePaintings = paintings => ({
    type: RECEIVE_PAINTINGS,
    paintings
})

export const receivePainting = painting => ({
    type: RECEIVE_PAINTING,
    painting
})

export const receivePhotographs = photographs => ({
    type: RECEIVE_PHOTOGRAPHS,
    photographs
})

export const receivePhotograph = photograph => ({
    type: RECEIVE_PHOTOGRAPH,
    photograph
})

export const receivePieces = pieces => ({
    type: RECEIVE_PIECES,
    pieces
})

export const receivePiece = piece => ({
    type: RECEIVE_PIECE,
    piece
})

export const fetchPaintings = () => dispatch => {
    APIUtil.fetchPaintings().then(paintings => {
        dispatch(receivePaintings(paintings))
    })
}

export const fetchPainting = (id) => dispatch => {
    APIUtil.fetchPainting(id).then(painting => {
        dispatch(receivePainting(painting))
    })
}

export const fetchPhotographs = () => dispatch => {
    APIUtil.fetchPhotographs().then(photographs => {
        dispatch(receivePhotographs(photographs))
    })
}

export const fetchPhotograph = (id) => dispatch => {
    APIUtil.fetchPhotograph(id).then(photograph => {
        dispatch(receivePhotograph(photograph))
    })
}

export const fetchPieces = () => dispatch => {
    APIUtil.fetchPieces().then(pieces => {
        dispatch(receivePieces(pieces))
    })
}

export const fetchPiece = (id) => dispatch => {
    APIUtil.fetchPiece(id).then(piece => {
        dispatch(receivePiece(piece))
    })
}