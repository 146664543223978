import { RECEIVE_USER_INFO, RECEIVE_UPDATED_USER_INFO } from '../actions/user_actions';
  
  const UserReducer = (state = { user: undefined, updated: undefined }, action) => {
    Object.freeze(state);
    let newState = Object.assign({}, state);
    switch(action.type) {
      case RECEIVE_USER_INFO:
        newState.user = action.user.data[0];
        return newState;
      case RECEIVE_UPDATED_USER_INFO:
        newState.updated = action.updated.data
        return newState;
      default:
        return state;
    }
  };
  
  export default UserReducer;