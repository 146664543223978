import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'



export default function CulturePieces(props) {

   const { fetchPhotographs, 
            photographs, 
           } = props

    useEffect(() => {
       window.scrollTo(0,0)
     }, [])

   useEffect(() => {
       if (!photographs)
    fetchPhotographs()

}, [photographs])

    return (
      <div className="main-body fade-in">
        <div className="main-page pad-top-50 smaller uppercase black">
        <div className="font-8 pad-left-8">
        <Link to={`/`}><span className="font-8">LVL</span></Link>
        <span className="font-8">/</span> 
        <Link to={'/paintings'}>
        <span className=" font-8">Cultural photography</span>
        </Link>
        </div>
        {photographs ? 
        <>
        {Object.keys(photographs).map((key) => {
            let series = photographs[key].series
            let title = photographs[key].title
            let location = photographs[key].location
            let year = photographs[key].year
            return (
            <Link to={`/culturalpieces/${photographs[key]._id}`}>
            <div className="painting-image-case">
                <img className="painting-image" src={`${photographs[key].imageURL}`}/>
                <div className="smaller uppercase">
                    <div>{series ? `Series:` + ` ` + `${series}` : null}</div>
                    <div>{title ? `${title}` : null}</div>
                    <div>{location ? `${location}` : null}</div>
                    <div>{year ? `${year}` : null }</div>
                </div>
            </div> 
            </Link>)
        })}
        </> : null
        }
        
          </div></div>
        );
    }