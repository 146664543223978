import { connect } from 'react-redux';
import Paintings from './paintings';
import { fetchPaintings, fetchPainting } from '../../actions/work_actions';

const mapStateToProps = (state) => {
  return {
   paintings: state.work.paintings,
   painting: state.work.painting
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPaintings: () => dispatch(fetchPaintings()),
    fetchPainting: (id) => dispatch(fetchPainting(id)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Paintings);