import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import Logo1 from '../../assets/costranslogo.png'
import Logo2 from '../../assets/costranslogo2.png'


export default function MainPage(props) {

  const [open, setOpen] = useState(null)
  const [mobilephoto, setMobilephotoURL] = useState(null)
  const [mobileURL, setMobileURL] = useState(null)
  const [mobilecss, setMobilecss] = useState(`mobile-image`)
  const [desktopURL, setDesktopURL] = useState(null)
  function setOpenSection(num) {
    if (open === num) {
      setOpen(null)
    } else {
      setOpen(num)
    }
  }

  useEffect(() => {
    window.scrollTo(0,0)
    
  }, [])

  useEffect(() => {
      if (mobilephoto) {
        setFrontImage()
      }
    
  }, [mobilephoto])

  useEffect(() => {

    setMobilephotoURL(1)
    setFrontImage()
    
  
}, [])


  function setFrontImage() {
    setTimeout(() => {
      if (mobilephoto === 5) {
        setMobileURL(`https://lorranlima.s3.us-east-2.amazonaws.com/titile2.JPG`)
        setDesktopURL(`https://lorranlima.s3.us-east-2.amazonaws.com/perf5.JPG`)

          setMobilephotoURL(1)
   
       
      } else {
        setMobilephotoURL(mobilephoto + 1)
       
      }
    }, 6200);

    if (mobilephoto === 1) {
      setMobileURL(`https://lorranlima.s3.us-east-2.amazonaws.com/smallconstellations2.JPG`)
      setDesktopURL(`https://lorranlima.s3.us-east-2.amazonaws.com/desktop2.jpeg`)

    } else if (mobilephoto === 2) {
      setMobileURL(`https://lorranlimaphotography.s3.us-east-2.amazonaws.com/FineArt/fineart2.jpg`)
      setDesktopURL(`https://lorranlima.s3.us-east-2.amazonaws.com/constellations.JPG`)
    } else if (mobilephoto === 3) {
      setMobileURL(`https://lorranlima.s3.us-east-2.amazonaws.com/NEPAL.JPG`)
      setDesktopURL(`https://lorranlima.s3.us-east-2.amazonaws.com/NEPAL.JPG`)
    } else if (mobilephoto === 4) {
      setMobileURL(`https://lorranlimaphotography.s3.us-east-2.amazonaws.com/FineArt/fineart1.jpg`)
      setDesktopURL(`https://lorranlima.s3.us-east-2.amazonaws.com/desktop4.jpeg`)

    } else if (mobilephoto === 5) {
      setMobileURL(`https://lorranlima.s3.us-east-2.amazonaws.com/happymen3.JPG`)
      setDesktopURL(`https://lorranlima.s3.us-east-2.amazonaws.com/perf5.JPG`)
    }
    
  }
  

 
    return (
      <div className="main-body fade-in">
        <div className="main-page">
          
          <div className="mobile-front">
            <img className="mobile-image fade-thru" src={mobileURL}></img>
         
          </div>

          <div className="desktop-front">
          <img className="desktop-image fade-thru" src={desktopURL}></img>
         
          </div>

          <div className="image-overlay"></div>
          
            <div className="image-overlay-slogan white uppercase bold-2 gradient-text">
            <div className="shadow-drop-2-center pad-20 gradient-text">
              <div className="gradient-texta">An Adventure<br/>into the Unknown</div>
              <div className="gradient-text font-14 op-6">Fine art paintings & portraits</div>
            </div>
            </div>





        
          </div>
          </div>
        );
    }