import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'



export default function Work(props) {

    useEffect(() => {
        window.scrollTo(0,0)
      }, [])

    return (
      <div className="main-body fade-in">
                <div className="banner fade-in">
        <img className="banner-image kenburns-top" src="https://lorranlima.s3.us-east-2.amazonaws.com/commercialbannerdesk.jpg"/>
        <img className="banner-image-mobile kenburns-top" src="https://lorranlima.s3.us-east-2.amazonaws.com/commercialbannermob.jpg"/>
        </div>
        <div className="main-page pad-top-20 smaller  black  top-drop">

        <div className="full-white-back">
        <div className="show-intro trans-back uppercase">
                    COMMERCIAL WORK
      </div>
      <div className="text-gray">
      All works are property of the respective brands.  These are displayed for portfolio purposes only.
      </div>
      <br/>
        <div className="fade-in">
          <div className="desktop-margin-top"/>
            <img className="work2" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/cw1.jpeg"></img>
            <img className="work2" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/cw2.jpeg"></img>

            <video className="work2" loop muted defaultMuted autoPlay playsInline src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/RPReplay_Final1647212245+(1).mp4">
            </video>
            <video className="work2" loop muted defaultMuted autoPlay playsInline src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/RPReplay_Final1647213238.mp4">
            </video>
            <img className="work2" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dovee.JPG"></img>
<img className="work2" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dovee2.JPG"></img>
            <img className="work2" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7495.JPG"></img> 


            
              
            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/2Q9A9947.jpg"></img>
            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/2Q9A9882.jpg"></img>

            <img className="work2" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/2Q9A0861.jpg"></img>
            <img className="work2" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/079A9799.JPG"></img>
            <br/>
            <img className="work3" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/shine1.jpg"></img>
            <img className="work3" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/shine2.jpg"></img>
            <img className="work3" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/shine3.jpg"></img>
            <img className="work3" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/shine4.jpg"></img>
<br/>


            
            <img className="work2" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7375.JPG"></img>

            <img className="work2" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/079A9760.JPG"></img>
<br/>

<img className="work4" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove1.JPG"></img>
<img className="work4" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove2.JPG"></img>
<img className="work4" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove3.JPG"></img>
<img className="work4" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove4.JPG"></img>
<img className="work4" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove5.JPG"></img>
<img className="work4" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove6.JPG"></img>

<img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove7.JPG"></img>
<img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove8.JPG"></img>
<img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove9.JPG"></img>
<img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove10.JPG"></img>
<img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove12.JPG"></img>

<img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove14.JPG"></img>
<img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove15.JPG"></img>
<img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove16.JPG"></img>
<img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove17.JPG"></img>
<img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove18.JPG"></img>
<img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove19.JPG"></img>
<img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove20.JPG"></img>
<img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove21.JPG"></img>
<img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove22.JPG"></img>
<img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove23.JPG"></img>
<img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove24.JPG"></img>
<img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove25.JPG"></img>
<img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove26.JPG"></img>
<img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove27.JPG"></img>
<img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove28.JPG"></img>
<img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove29.JPG"></img>
<img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove30.JPG"></img>
<img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove31.JPG"></img>
<img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove32.JPG"></img>
<img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove33.JPG"></img>
<img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove34.JPG"></img>
<img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove35.JPG"></img>
<img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove36.JPG"></img>
<img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove37.JPG"></img>
<img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove38.JPG"></img>
<img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove39.JPG"></img>
<img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove40.JPG"></img>
<img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove41.JPG"></img>
<img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/dove42.JPG"></img>


            
            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/079A9594.JPG"></img>

            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/079A9623.JPG"></img>
           
<br/>
            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_4763.JPG"></img>
            <video className="work" loop muted defaultMuted autoPlay playsInline src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/RPReplay_Final1647213298.mp4">
            </video>
            
<br/>
            <img className="work2" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/shine6.JPG"></img>
           <br/>
            

            <img className="work2" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/shine10.jpg"></img>
           
            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/shine5.jpg"></img>
            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/shine11.jpg"></img>
            <br/>
            <img className="work2" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/shineredo1.jpg"></img>
            <br/>

            <video className="work2" loop muted defaultMuted autoPlay playsInline src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/RPReplay_Final1647212450.mp4">
            </video>


            <video className="work2" loop muted defaultMuted autoPlay playsInline src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/RPReplay_Final1647215641.mp4">
            </video>
            <br/>
            <video className="work" loop muted defaultMuted autoPlay playsInline src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/RPReplay_Final1647387027.mp4">
            </video>
            <video className="work" loop muted defaultMuted autoPlay playsInline src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/RPReplay_Final1647217848+(1)+(1).mp4">
            </video>
            <img className="work2" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7395.JPG"></img>
<br/>

            <img className="work3" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_8955.JPG"></img>
            <img className="work3" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_8956.JPG"></img>
<br/>
            <img className="work2" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_8957.JPG"></img>
            <img className="work2" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_8958.JPG"></img>
            <img className="work2" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_8959.JPG"></img>
            <img className="work2" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_8960.JPG"></img>
            <br/>
            <img className="work2" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_8961.JPG"></img>
            <br/>


            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7290.jpg"></img>

           
            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7408.JPG"></img>
            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7410.JPG"></img>

            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7418.JPG"></img>
            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7419.JPG"></img>

            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7430.jpg"></img>
            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7438.jpg"></img>
            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7448.JPG"></img>
            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7446.JPG"></img>

            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7452.JPG"></img>
            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7453.JPG"></img>
            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7455.JPG"></img>
            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7454.JPG"></img>
            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7456.JPG"></img>
            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7450.JPG"></img>
            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7471.jpg"></img>
            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7475.jpg"></img>
            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7491.JPG"></img>
            <img className="work2" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7493.JPG"></img>
            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7260.jpg"></img>
            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7264.jpg"></img>
            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7268.jpg"></img>
            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7284.JPG"></img>
            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7311.JPG"></img>
            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7319.JPG"></img>
            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7344.JPG"></img>
            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7353.JPG"></img>
            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7355.JPG"></img>
            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7356.JPG"></img>
            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7357.jpg"></img>
            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7374.JPG"></img>

            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7382.JPG"></img>
            <img className="work" src="https://lorrancommercialwork.s3.us-east-2.amazonaws.com/IMG_7390.JPG"></img>
            </div>
            </div>
        </div>
      </div>
        );
    }