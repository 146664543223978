import { connect } from 'react-redux';
import Dashboard from './dashboard';
import { fetchCourseMaterial } from '../../actions/course_actions';
import { logout } from '../../actions/session_actions';

const mapStateToProps = (state) => {
  return {
   sections: state.course.sections,
   course_id: state.user.course_id,
   currentUser: state.session.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCourseMaterial: (course_id) => dispatch(fetchCourseMaterial(course_id)),
    logout: user => dispatch(logout(user)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);