import * as APIUtil from '../util/session_api_util';
import * as UserAPIUtil from '../util/users_util';

export const RECEIVE_USER_INFO = "RECEIVE_USER_INFO";
export const RECEIVE_UPDATED_USER_INFO = "RECEIVE_UPDATED_USER_INFO";

export const receiveUserInfo = user => ({
    type: RECEIVE_USER_INFO,
    user
})

export const receiveUpdatedUserInfo = updated => ({
    type: RECEIVE_UPDATED_USER_INFO,
    updated
})

export const fetchUser = (id) => dispatch => {
    APIUtil.fetchUser(id).then(user => {
        dispatch(receiveUserInfo(user))
    })
}

export const updateUserMicrobladingSessionsCompleted = (id, update) => dispatch => {
    UserAPIUtil.updateUserMicrobladingSessionsCompleted(id, update).then(user => {
        dispatch(receiveUpdatedUserInfo(user))
    })
}