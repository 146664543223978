import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'



export default function CulturePiece(props) {

   const { 
            photograph, 
            fetchPhotograph,
            match } = props

   let id = match.params.id

   const [selectedImage, setSelectedImage] = useState(null)
   const [mainURL, setMainURL] = useState(null)
   const [mainIsProp, setMainIsProp] = useState(false)

   useEffect(() => {
    window.scrollTo(0,0)
  }, [])

   useEffect(() => {
    if (!photograph || photograph._id !== id) {
    fetchPhotograph(id) }

}, [photograph])

useEffect(() => {
    if (photograph) {
 setSelectedImage(photograph.imageURL);
 setMainURL(photograph.imageURL)
    }

}, [photograph])

function selectImage(image) {
    if (image !== selectedImage && image !== mainURL) {
        setSelectedImage(image)
        setMainIsProp(true)
    } else if (image === mainURL && image !== selectedImage) {
        setSelectedImage(image)
        setMainIsProp(false)

    } else {

    }
}



    return (
      <div className="main-body fade-in">
        <div className="main-page pad-top-50 smaller uppercase black">
        <div className="font-8 pad-left-8">
        <Link to={`/`}><span className="font-8">LVL</span></Link>
        <span className="font-8">/</span> 
        <Link to={'/paintings'}>
        <span className=" font-8">Cultural Pieces</span>
        </Link>
        <span className="font-8">{photograph ? `/` + ` ` + photograph.series + ` ` + photograph.title : null} </span> 
        </div>
        {photograph ? 
        <>
        
       
       
            <div className="painting-image-case">
                <img className="painting-image" src={`${selectedImage}`}/>
                <div className="image-series-box">
                {photograph.image1 && photograph.image1 !== selectedImage ? <img onClick={() => selectImage(photograph.image1)} className="image-series" src={`${photograph.image1}`}/> : null}
                {photograph.image2 && photograph.image2 !== selectedImage ? <img onClick={() => selectImage(photograph.image2)} className="image-series" src={`${photograph.image2}`}/> : null}
                {photograph.image3 && photograph.image3 !== selectedImage ? <img onClick={() =>  selectImage(photograph.image3)} className="image-series" src={`${photograph.image3}`}/> : null}
                {photograph.image4 && photograph.image4 !== selectedImage ? <img onClick={() =>  selectImage(photograph.image4)} className="image-series" src={`${photograph.image4}`}/> : null}
                {photograph.image5 && photograph.image5 !== selectedImage ? <img onClick={() =>  selectImage(photograph.image5)} className="image-series" src={`${photograph.image5}`}/> : null}
                {photograph.image6 && photograph.image6 !== selectedImage ? <img onClick={() =>  selectImage(photograph.image6)} className="image-series" src={`${photograph.image6}`}/> : null}
                {mainIsProp ? <img className="image-series" onClick={() =>  selectImage(mainURL)} src={mainURL}></img> : null}
                </div>
                
                <div className="smaller uppercase">
                    <div>{photograph.series ? `From the Series:` + ` ` + `${photograph.series}` : null}</div>
                    <div>{photograph.title ? `${photograph.title}` : null}</div>
                    <div>{photograph.locaation ? `${photograph.locaation}` : null}</div>
                    <div>{photograph.year ? `${photograph.year}`: null}</div>
                </div>
            </div> 
        
        </> : null
        }
        
          </div></div>
        );
    }