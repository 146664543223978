import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'



export default function AboutMe(props) {

    useEffect(() => {
        window.scrollTo(0,0)
      }, [])

    return (
    
      <div className="main-body">
                <div className="main-page smaller  black fade-in">
        <br/>
          <div className="smaller trans-back bold  justify width-90 opacity-90">
            <br/><br/>




            <div className="fade-in width-600">
          <div className="inline-block text-gray">

            <img className="artist-about-me width-40 left" src="https://lorranlima.s3.us-east-2.amazonaws.com/aboutme1.jpeg"/>
            
 
            Born & raised in Brazil, Lorran is now based in New York where he is pursuing his passion for photography and art. His keen eye for beauty & life has sparked this passion. He started out telling stories through photos - specifically by capturing the beauty of the world through his travels around the globe. <br/><br/>
          <img className="artist-about-me width-30 right" src="Https://lorranlima.s3.us-east-2.amazonaws.com/IMG_7731.jpg"/>
          His passion for photography has further grown by taking photos of people - where they can portray their stories & life through an image. He is passionate about helping children in need and using his platform to be able to tell their stories through a photograph.

<br/><br/>
"Being an artist isn’t just about putting a brush to a canvas, or  capture a photograph. Art can and means so much more than that to so many people. 
Art you have to feel
You need to feel it. Furthermore, art never stops.... Or its pointless to the eye." -Lorran
<img className="width-40 right pad-top-0" src="https://lorranlima.s3.us-east-2.amazonaws.com/IMG_7727.JPG"/>

<br/><br/>
<img className="artist-about-me width-40 left no-pad-top" src="https://lorranlima.s3.us-east-2.amazonaws.com/IMG_9836+3.jpg"/>
Lorran  has a love for paintings and all types of abstract art.
Art has being around his life since he was a kid. 
 He's explored many different mediums and styles during this years since he moved to New York City, and is excited to share them with the world.
<br/><br/>
His passion for photography has transcended into commercial photography as represented by Lorran's extensive work for one of the most renowned brands on the globe, unilever.  His work includes the entire collection of Dove's womens hair products and has spanned over the period of the last 3 years. <br/><br/>
Lorran's commercial work has gotten him featured spots in Oprah Magazine, Harper's Bazaar U.S., and is used in major stores across the U.S., including Target; CVS pharmacy; Bed, Bath, and Beyond; Instacart, Amazon, Walmart, Walgreens, and more.

          </div>
          <div className="row">

 <img className="width-100 " src="https://lorranlima.s3.us-east-2.amazonaws.com/IMG_7729.JPG"/></div>
</div>

          </div>
   

          </div>
          </div>
        );
    }