import React from 'react';
import { Route } from 'react-router-dom';
import { AuthRoute, ProtectedRoute } from '../util/route_util';
import { Switch } from 'react-router-dom';
import MainPage from './main/main_page';
import LoginContainer from './session/login_container';
import DashboardContainer from './dashboard/dashboard_container'
import Paintings from './main/paintings_container';
import Painting from './main/painting_container';
import Photograph from './main/photograph_container'
import AboutMe from './main/aboutme'
import './App.css';
import Navbar from './navbar/navbar';
import Photographs from './main/photographs_container';
import CulturePieces from './main/culturepieces_container';
import CulturePiece from './main/culture_piece_container';
import Work from './main/commercialwork';
import Prints from './main/prints_container';
import Print from './main/print_container';
import Humanitarian from './main/humanitarian'
import childlabor_container from './main/childlabor_container';
import causes_container from './main/causes_container';


const App = () => (
  <div className="main">
      <Navbar/>
      <AuthRoute exact path="/" component={MainPage} />
      <AuthRoute exact path="/aboutme" component={AboutMe} />
      <AuthRoute exact path="/paintings" component={Paintings} />
      <AuthRoute exact path="/prints" component={Prints} />
      <AuthRoute exact path="/prints/:id" component={Print} />
      <AuthRoute exact path="/paintings/:id" component={Painting} />
      <AuthRoute exact path="/photographs" component={Photographs} />
      <AuthRoute exact path="/work" component={Work} />
      <AuthRoute exact path="/humanitarianism" component={Humanitarian} />
      <AuthRoute exact path="/childlabor" component={childlabor_container} />
      <AuthRoute exact path="/causes" component={causes_container} />
      <AuthRoute exact path="/photographs/:id" component={Photograph} />
      <AuthRoute exact path="/culturalpieces/" component={CulturePieces} />
      <AuthRoute exact path="/culturalpieces/:id" component={CulturePiece} />
      <ProtectedRoute exact path="/dashboard" component={DashboardContainer} />
  </div>
);

export default App;