import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'



export default function Childlabor(props) {

   const { fetchPhotographs, 
            photographs, 
           } = props

    const [show, setShow] = useState(true)

    useEffect(() => {
       window.scrollTo(0,0)
     }, [])

   useEffect(() => {
       if (!photographs)
    fetchPhotographs()

}, [photographs])

useEffect(() => {


}, [])

    return (
      <div className="main-body">
 <div className="banner fade-in">
        <img className="banner-image kenburns-top" src="https://lorranlima.herokuapp.com/assets/childlabor-5a23438a8ec7d44bb090420c372337980d8806883e975023d053ba0d595fb5c5.png"/>
        <img className="banner-image-mobile kenburns-top" src="https://lorranlima.herokuapp.com/assets/childlabor2-7bbd1ce4d54a509098533f37965a350221d6f58afc5d8182ea643202232b8031.png"/>
        </div>
        <div className="main-page pad-top-20 smaller  black  top-drop">

        <div className="full-white-back">
        <div className="show-intro trans-back uppercase">
                    CHILD LABOR
      </div>
      <div className="text-gray">
      As a photographer, one of my missions is to be able to tell a story through a single photo. During my travels, I’ve been exposed to a lot of different stories & one that had a big impact on me is to see child labor. This is a practice that is unfortunately still common in different parts of the world and unfortunately the stories of why this still happens nowadays stays the same. Whether it’s because of lack of resources or even situational where children have to work to help support their families - it is a phenomenon that needs attention to be solved. My wish is for a world where one day hopefully child labor is gone. A future where children are allowed to be children - to grow, to learn, to play. It will be a journey but all we can do is to take it one step at a time. And for me, the first step is driving awareness that this is an issue that still happens globally. I will continue to tell this story through my photos. Keep watching this space as I continue to share more stories & find ways to be able to help end child labor.
      </div>

      <div className="gray-back">
        {photographs ? 
        <>
        {Object.keys(photographs).map((key) => {
            let series = photographs[key].series
            let title = photographs[key].title
            let location = photographs[key].location
            let year = photographs[key].year
            let childlabor = photographs[key].childlabor

            if (childlabor) {
    
            return (
          
       
           
            <div className="painting-image-case fade-in">
            <div className="painting-image-inside">
                <img className="painting-image" src={`${photographs[key].imageURL}`}/>
                <div className="smaller uppercase">
                    <div className="text-gray">{series ? `Series:` + ` ` + `${series}` : null}</div>
                    <div>{title ? `${title}` : null}</div>
                    <div className="text-gray-2">{location ? `${location}` : null}</div>
                    <div className="text-gray italic">{year ? `${year}` : null }</div>
                </div>
                </div>
            </div> 
           )  }
        })}
        </> : null

        } </div>
        </div>
        </div>
          </div> 
        );
    }