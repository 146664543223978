import { connect } from 'react-redux';
import CulturePieces from './culturepieces';
import { fetchPieces, fetchPiece } from '../../actions/work_actions';

const mapStateToProps = (state) => {
  return {
   photographs: state.work.pieces,
   photograph: state.work.piece
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPhotographs: () => dispatch(fetchPieces()),
    fetchPhotograph: (id) => dispatch(fetchPiece(id)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CulturePieces);