import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'



export default function Causes(props) {

   const { fetchPhotographs, 
            photographs, 
           } = props

    const [show, setShow] = useState(true)
    const [selectedbtn, setSelectedBtn] = useState(3)

    useEffect(() => {
       window.scrollTo(0,0)
     }, [])

   useEffect(() => {
       if (!photographs)
    fetchPhotographs()

}, [photographs])

useEffect(() => {


}, [])

function selectBtn(num) {
    setSelectedBtn(num)
}

    return (
      <div className="main-body">
 <div className="banner fade-in">
        <img className="banner-image kenburns-top" src="https://lorranlima.s3.us-east-2.amazonaws.com/causesbanner.jpg"/>
        <img className="banner-image-mobile kenburns-top" src="https://lorranlima.s3.us-east-2.amazonaws.com/causesbannermob.jpg"/>
        </div>
        <div className="main-page pad-top-20 smaller  black  top-drop">

        <div className="full-white-back">
        <div className="show-intro uppercase">
                    CAUSES
      </div>
      <br/>
        <div className="row-scroll">
                        <span onClick={() => selectBtn(1)} className={selectedbtn === 1 ? "detail-btn selected-btn fit-content" : "detail-btn fit-content"}> Workshops</span>
                        <span onClick={() => selectBtn(2)} className={selectedbtn === 2 ? "detail-btn selected-btn fit-content" : "detail-btn fit-content"}> Street Living</span>
                        <span onClick={() => selectBtn(3)} className={selectedbtn === 3 ? "detail-btn selected-btn fit-content" : "detail-btn fit-content"}> School Under The Bridge</span>
                        <span onClick={() => selectBtn(4)} className={selectedbtn === 4 ? "detail-btn selected-btn fit-content" : "detail-btn fit-content"}> Alone</span>
                        <span onClick={() => selectBtn(5)} className={selectedbtn === 5 ? "detail-btn selected-btn fit-content" : "detail-btn fit-content"}> Cultural Diversity</span>
        </div>
        <br/>
        <div className={selectedbtn === 3 ? "text-gray" : "hidden"}>
                The Free School Under the Bridge is a private school in Delhi, India. It provides free-of-cost tuition to underprivileged children. Almost all the students come from the slums situated adjacent to the Yamuna river and are enrolled in nearby government-run schools. Started in 2006 by grocery shop owner Rajesh Kumar Sharma with just two children, the school now has a strength of more than 300 students. Seven teachers volunteering in their free time help these students to better understand their school curriculum. The school is run in two shifts with a morning shift for boys and an afternoon shift for girls. It has no permanent building and its makeshift campus is situated under a Delhi Metro bridge with pillars serving as its boundary marker and blackboards painted on a wall of Yamuna Bank Metro Depot. It receives no help from either the government or any non-governmental organisation.

        </div>
        <div className={selectedbtn === 1 ? "text-gray" : "hidden"}>
        Dove DSEP Workshops<br/>
Learning how to be confident with who you are, because  beauty is not just one specific type, we are all unique. 
2019 Dove Self-Esteem Hair Workshops  Los Angeles California.

        </div>

        <div className={selectedbtn === 4 ? "text-gray" : "hidden"}>
        More than 30,000 children die every day from poverty-related causes.  Today, poverty kills a child every 3 seconds.  Children are the main victims of poverty and poverty is the main cause of child rights violations.

        </div>

          <br/> 

        {photographs ? 
        <>
        {Object.keys(photographs).map((key) => {
            let series = photographs[key].series
            let title = photographs[key].title
            let location = photographs[key].location
            let year = photographs[key].year
            let causes = photographs[key].causes
            let workshops = photographs[key].workshop
            let streetliving = photographs[key].streetliving
            let alone = photographs[key].alone
            let culturaldiversity = photographs[key].culturaldiversity

            if (causes) {

                if (!workshops && !streetliving && !alone && !culturaldiversity && selectedbtn === 3) {
    
            return (
          

            <div className="painting-image-case fade-in">
            <div className="painting-image-inside">
                <img className="painting-image" src={`${photographs[key].imageURL}`}/>
                <div className="smaller uppercase">
                    <div className="text-gray">{series ? `Series:` + ` ` + `${series}` : null}</div>
                    <div>{title ? `${title}` : null}</div>
                    <div className="text-gray-2">{location ? `${location}` : null}</div>
                    <div className="text-gray italic">{year ? `${year}` : null }</div>
                </div>
                </div>
            </div> 
           )  }
        if (workshops && selectedbtn === 1) {
            return (
          
       
           
                <div className="painting-image-case fade-in">
                <div className="painting-image-inside">
                    <img className="painting-image" src={`${photographs[key].imageURL}`}/>
                    <div className="smaller uppercase">
                        <div className="text-gray">{series ? `Series:` + ` ` + `${series}` : null}</div>
                        <div>{title ? `${title}` : null}</div>
                        <div className="text-gray-2">{location ? `${location}` : null}</div>
                        <div className="text-gray italic">{year ? `${year}` : null }</div>
                    </div>
                    </div>
                </div> 
               ) 
        } if (alone && selectedbtn === 4) {
            return (
          
       
           
                <div className="painting-image-case fade-in">
                <div className="painting-image-inside">
                    <img className="painting-image" src={`${photographs[key].imageURL}`}/>
                    <div className="smaller uppercase">
                        <div className="text-gray">{series ? `Series:` + ` ` + `${series}` : null}</div>
                        <div>{title ? `${title}` : null}</div>
                        <div className="text-gray-2">{location ? `${location}` : null}</div>
                        <div className="text-gray italic">{year ? `${year}` : null }</div>
                    </div>
                    </div>
                </div> 
               ) 
        } if (streetliving && selectedbtn === 2) {
            return (
          
       
           
                <div className="painting-image-case fade-in">
                <div className="painting-image-inside">
                    <img className="painting-image" src={`${photographs[key].imageURL}`}/>
                    <div className="smaller uppercase">
                        <div className="text-gray">{series ? `Series:` + ` ` + `${series}` : null}</div>
                        <div>{title ? `${title}` : null}</div>
                        <div className="text-gray-2">{location ? `${location}` : null}</div>
                        <div className="text-gray italic">{year ? `${year}` : null }</div>
                    </div>
                    </div>
                </div> 
               ) 
        } if (culturaldiversity && selectedbtn === 5) {
            return (
          
       
           
                <div className="painting-image-case fade-in">
                <div className="painting-image-inside">
                    <img className="painting-image" src={`${photographs[key].imageURL}`}/>
                    <div className="smaller uppercase">
                        <div className="text-gray">{series ? `Series:` + ` ` + `${series}` : null}</div>
                        <div>{title ? `${title}` : null}</div>
                        <div className="text-gray-2">{location ? `${location}` : null}</div>
                        <div className="text-gray italic">{year ? `${year}` : null }</div>
                    </div>
                    </div>
                </div> 
               ) 
        }}
        })}
        </> : null

        } </div>
        </div>
        </div>
 
        );
    }