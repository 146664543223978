import { combineReducers } from 'redux';
import session from './session_reducer';
import user from './users_reducer';
import errors from './errors_reducer';
import course from './course_api_reducer';
import work from './work_api_reducer';

const RootReducer = combineReducers({
  session,
  user,
  errors,
  course,
  work

});

export default RootReducer;