import * as APIUtil from '../util/course_api_util';

export const RECEIVE_COURSE_SECTIONS = "RECEIVE_COURSE_SECTIONS";


export const receiveCourseSections = sections => ({
    type: RECEIVE_COURSE_SECTIONS,
    sections
})

export const fetchCourseMaterial = (course_id) => dispatch => {
    APIUtil.fetchCourseMaterial(course_id).then(sections => {
        dispatch(receiveCourseSections(sections))
    })
}