import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'



export default function Prints(props) {

   const { fetchPhotographs, 
            photographs, 
           } = props

    const [show, setShow] = useState(true)
    const [view, setView] = useState(2)

    useEffect(() => {
       window.scrollTo(0,0)
     }, [])

   useEffect(() => {
       if (!photographs)
    fetchPhotographs()

}, [photographs])

useEffect(() => {


}, [])



    return (
      <div className="main-body">
        
        <div className="banner fade-in">
        <img className="banner-image kenburns-top" src="https://lorranlima.herokuapp.com/assets/PrintsBanner-bf4ef69514410fc3f7ab6f2dbb62fe00807091f2b12f11c577ff810619a13e5c.jpg"/>
        <img className="banner-image-mobile kenburns-top" src="https://lorranlima.s3.us-east-2.amazonaws.com/banner4mob.jpeg"/>
        </div>
        {show ? 
        <div className="main-page pad-top-20 smaller black top-drop">
        <div className="full-white-back">
        <div className="show-intro uppercase">
                    PRINTS
      </div>
      <div className="text-gray">
      All works are available for purchase –<br/>please click on an image for further information.
      </div>
      <br/>
      <div className="view">
        View 
        <span onClick={() => setView(1)} className={view === 1 ? "view-spn selected-btn" : "view-spn"}>
          1
        </span>
        <span onClick={() => setView(2)} className={view === 2 ? "view-spn selected-btn" : "view-spn"}>
          2
        </span>
      </div>
        {photographs ? 
        <>
        {Object.keys(photographs).map((key) => {
            let series = photographs[key].series
            let title = photographs[key].title
            let location = photographs[key].location
            let year = photographs[key].year
            let print = photographs[key].print

            if (print) {
    
            return (
          
            <Link to={`/prints/${photographs[key]._id}`}>
           
           <div className={view === 1 ? "painting-image-case fade-in" : "painting-image-case-half fade-in"}>
            <div className="painting-image-inside">
                <img className="painting-image" src={`${photographs[key].imageURL}`}/>
                <div className="smaller uppercase">
                    <div className="text-gray">{series ? `Series:` + ` ` + `${series}` : null}</div>
                    <div>{title ? `${title}` : null}</div>
                    <div className="text-gray-2">{location ? `${location}` : null}</div>
                    <div className="text-gray italic">{year ? `${year}` : null }</div>
                </div>
                </div>
            </div> 
            </Link>)  }
        })}
        </> : null
        } </div>
        
          </div> : <div className="show-full-page">
                    <div className="show-intro">
                    PORTRAITS
      </div></div> }</div>
        );
    }