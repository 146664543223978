import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'



export default function Print(props) {

   const { 
            photograph, 
            fetchPhotograph,
            match } = props

   let id = match.params.id

   const [selectedImage, setSelectedImage] = useState(null)
   const [mainURL, setMainURL] = useState(null)
   const [mainIsProp, setMainIsProp] = useState(false)
   const [selectedbtn, setSelectedBtn] = useState(1)

   useEffect(() => {
    window.scrollTo(0,0)
  }, [])

   useEffect(() => {
    if (!photograph || photograph._id !== id)
    fetchPhotograph(id)

}, [photograph])

useEffect(() => {
    if (photograph) {
 setSelectedImage(photograph.imageURL);
 setMainURL(photograph.imageURL)
    }

}, [photograph])

function selectImage(image) {
    if (image !== selectedImage && image !== mainURL) {
        setSelectedImage(image)
        setMainIsProp(true)
    } else if (image === mainURL && image !== selectedImage) {
        setSelectedImage(image)
        setMainIsProp(false)

    } else {

    }
}

function selectBtn(num) {
    setSelectedBtn(num)
}


    return (
      <div className="main-body fade-in">
        <div className="main-page pad-top-50 smaller uppercase black">
        <Link to="/prints">
        <div className="back-btn">‹ Back to Prints</div>
        </Link>
        {photograph ? 
        <>
        
       
       
            <div className="">
                <div className="half r">
                <img className="painting-image height-max" src={`${selectedImage}`}/>
                </div>
                <div className="half ">
                <div className="image-series-box">
                {photograph.image1 && photograph.image1 !== selectedImage ? <img onClick={() => selectImage(photograph.image1)} className="image-series" src={`${photograph.image1}`}/> : null}
                {photograph.image2 && photograph.image2 !== selectedImage ? <img onClick={() => selectImage(photograph.image2)} className="image-series" src={`${photograph.image2}`}/> : null}
                {photograph.image3 && photograph.image3 !== selectedImage ? <img onClick={() =>  selectImage(photograph.image3)} className="image-series" src={`${photograph.image3}`}/> : null}
                {photograph.image4 && photograph.image4 !== selectedImage ? <img onClick={() =>  selectImage(photograph.image4)} className="image-series" src={`${photograph.image4}`}/> : null}
                {photograph.image5 && photograph.image5 !== selectedImage ? <img onClick={() =>  selectImage(photograph.image5)} className="image-series" src={`${photograph.image5}`}/> : null}
                {photograph.image6 && photograph.image6 !== selectedImage ? <img onClick={() =>  selectImage(photograph.image6)} className="image-series" src={`${photograph.image6}`}/> : null}
                {mainIsProp ? <img className="image-series" onClick={() =>  selectImage(mainURL)} src={mainURL}></img> : null}
                </div>

                
                <div className="smaller uppercase">
                    <div>{photograph.series ? `From the Series:` + ` ` + `${photograph.series}` : null}</div>
                    <div>{photograph.title ? `${photograph.title}` : null}</div>
                    <div>{photograph.locaation ? `${photograph.locaation}` : null}</div>
                    <div>{photograph.year ? `${photograph.year}`: null}</div>

                    <br/>
                    <div>
                        <span onClick={() => selectBtn(1)} className={selectedbtn === 1 ? "detail-btn selected-btn" : "detail-btn"}> Sizes and Pricing</span>
                        <span onClick={() => selectBtn(2)} className={selectedbtn === 2 ? "detail-btn selected-btn" : "detail-btn"}> More Details</span>
                    </div>
                    <div className={selectedbtn === 1 ? "pad-10" : "hidden"}>Prints are available for print in four sizes:<br/>
                    11 x 14 in. &nbsp;&nbsp; $600<br/>
                    16 x 20 in. &nbsp;&nbsp; $800<br/>
                    20 x 24 in. &nbsp;&nbsp; $1200<br/>
                    30 x 40 in. &nbsp;&nbsp; $3000<br/>
                    </div>
                    <div className={selectedbtn === 2 ? "pad-10" : "hidden"}>
                    Prices include tax + worldwide shipping.  Prints do not contain an artist's signature.
                    <br/> Pieces are printed to order; no returns accepted.  Shipping from New York, New York.
                    </div>
                
               
                </div>
       

            <a href={`mailto:contact@lorranlima.com?subject=Hi! I'm interested in this piece ID ${photograph._id} &body=Please tell me more about this piece.`}>
                <div className="inquire-btn">
                Inquire About This Print
                </div>
                </a>
                </div>
                </div> 
        
        </> : null
        }
        
          </div></div>
        );
    }